import config from "../../config";
import { useVideoScreen } from "./videoFunction";

const VideoScreen = () => {
    const { videoRef, videoLoop, videoIndex } = useVideoScreen();

    return (
        <div className="flex flex-row justify-center items-center h-screen">
            <video
                ref={videoRef}
                src={videoLoop[videoIndex]}
                autoPlay
                loop
                muted
                className="overflow-hidden w-full aspect-auto"
            />
        </div>
    );
};

export default VideoScreen;